<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="modal"
    :modal-width="40"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Respond Request</span>
    </template>
    <template v-slot>
      <UserDetailedView :user="userCandidate" />
      <div v-if="!isLoading" class="pt-6">
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="selectedResponse"
            title="Response"
            label="title"
            :options="responseList"
            rules="required"
            @change="updateResponse"
            @deSelect="updateResponse"
          />
        </InputFieldWrapper>
        <InputFieldWrapper v-if="isTime">
          <UiInput
            v-model="time"
            type="text"
            name="Time"
            title="TIME_IN_MINS"
            label="Time"
            rules="required"
            placeholder="Time"
            class="flex-1 w-1/5"
          />
        </InputFieldWrapper>
        <InputFieldWrapper v-if="isCustom">
          <UiInput
            v-model="customMessage"
            type="textarea"
            name="Message"
            title="MESSAGE"
            label="Message"
            placeholder="WRITE"
            class="flex-1 w-1/5"
          />
        </InputFieldWrapper>
      </div>
    </template>
    <template v-slot:footer>
      <div class="p-1 flex gap-4 flex-row">
        <button
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-9 w-23.5 flex justify-center items-center primary-button-outline"
          :disabled="isLoading"
          @click="handleClick('close')"
        >
          Cancel
        </button>
        <button
          :disabled="isLoading"
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-9 w-23.5 flex justify-center items-center primary-button"
          @click="respondToPickupPerson"
        >
          <template v-if="isLoading">
            <Loader />
          </template>
          <template v-else>
            <span>Send</span>
          </template>
        </button>
      </div>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UserDetailedView from '@src/components/userDetailedView.vue'
import { mapActions, mapState } from 'vuex'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import generalConstants from '@src/constants/general-constants.js'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { pickupRequestStyle } from '@src/utils/settings/tenant-user.util.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    // ValidationObserver,
    UiSingleSelect,
    Loader,
    UserDetailedView,
    UiInput,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentRequest: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      students: [],
      pickUp: 'pickUp',
      selectedResponse: null,
      isLoading: false,
      isTime: false,
      isCustom: false,
      userCandidate: null,
      time: null,
      customMessage: '',
      acknowledgeRequest: {
        status: 'acknowledged',
        title: 'Your request is Acknowledged',
        message: 'Your request is Acknowledged',
      },
      responseList: [
        {
          status: 'student_coming',
          title: 'Student is on the way. Be ready!',
          message: 'Student is on the way. Be ready!',
        },
        {
          status: 'student_coming_in_few_mins',
          title: 'Student is coming shortly. Hang tight!',
          message: 'Student is coming shortly. Hang tight!',
        },
        {
          status: 'student_busy',
          title: 'Student is engaged right now. Please come back later',
          message: 'Student is engaged right now. Please come back later',
        },
        {
          status: 'custom_message',
          title: 'Custom Message',
          message: 'Custom Message',
        },
      ],
      responseData: {
        status: '',
        message: '',
      },
    }
  },

  computed: {
    ...mapState('layout', ['currentCampusScope']),
  },
  mounted() {
    this.getUserInfo(this.currentRequest.student)
    if (!this.currentRequest?.pickup_request_logs?.some((log) => log.status === 'acknowledged')) {
      this.responseList.unshift(this.acknowledgeRequest)
    }
  },
  methods: {
    getUserInfo(data) {
      let queryParam = paginationRangeHandler(data?.range, generalConstants.MODAL_RECORD_LIMIT)
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
          this.userCandidate.requestStatus = this.currentRequest.status
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    pickupRequestStyle,
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle', 'fetch')
          break
      }
    },

    updateResponse() {
      let status = this.selectedResponse.status
      this.responseData.status = status
      this.isCustom = status === 'custom_message'

      this.isTime = ['student_coming_in_few_mins', 'student_busy'].includes(status)
    },
    updateMessage(status) {
      if (status === 'student_coming_in_few_mins')
        this.responseData.message = ` Student is coming shortly. Please wait for ${this.time} minutes `
      else if (status === 'student_busy')
        this.responseData.message = `Student is engaged right now. Please come back after ${this.time} minutes`
      else if (status === 'custom_message') this.responseData.message = this.customMessage
    },
    async respondToPickupPerson() {
      if (this.isTime || this.responseData.status === 'custom_message')
        this.updateMessage(this.responseData.status)
      else this.responseData.message = this.selectedResponse.message
      try {
        this.$store.commit('layout/IS_LOADING', true)

        let payload = {
          status: this.responseData.status || '',
          message: this.responseData.message || '',
          pickup_request_id: this.currentRequest?.id || '',
        }
        await this.respondToPickupMemvber(payload).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
        })
        this.handleClick('close')
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('pickup', ['respondToPickupMemvber']),
    ...mapActions('users', ['getUserDetails']),
  },
}
</script>
