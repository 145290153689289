<template>
  <div>
    <PickupFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      feature="Student Dispatcher"
      tooltip-title="ACTIVE_REQ_SUPPORT"
      title="Active Requests"
      @apply="applyFilters"
    />
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(requestsList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            {{ tableHeaders[0].dataTableName }}
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Last Three Activities')" colspan="2">
            {{ tableHeaders[1].dataTableName }}
          </TH>
          <TH
            v-for="head in tableHeaders.slice(2).filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, ['Pickup Person'])"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody
          v-for="(request, index) in requestsList"
          :key="index + request.id"
          :style="request.new && upComingRow()"
          @click="request.new = false"
        >
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <span @click="redirectToStudentMode(request.student, 'support-active-request')">
              <SingleUserDisplay
                :key="index + request.id"
                :user="request.student"
                label="full_name"
                :image="true"
                :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
                :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
              />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Last Three Activities')">
            <div
              v-for="(requestResponse, ind) in request.pickup_request_logs.slice(0, 3)"
              :key="ind"
              class="flex justify-around"
            >
              <span v-if="requestResponse.status === 'custom_message'">
                Message:"{{ requestResponse.message }}"
              </span>
              <span v-if="requestResponse.status === 'request_sent'">Request Is Received</span>

              <span v-else>
                {{ requestResponse.message }}
              </span>
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Last Three Activities')">
            <div
              v-for="(requestTime, ind) in request.pickup_request_logs.slice(0, 3)"
              :key="ind"
              @click="$router.push({ name: 'request-details', params: { id: request.id } })"
            >
              <span class="text-primary-green">
                {{ $filters.dateFormat(requestTime.inserted_at) }}
              </span>
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Pickup Person', ['Pickup Person'])">
            <SingleUserDisplay
              :user="request.pickup_person"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Main Guardian')">
            <SingleUserDisplay
              :user="request?.student?.student_info?.main_guardian"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Status')"
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'request-details',
                params: { id: request.id, feature: 'Student Dispatcher' },
              })
            "
          >
            <div :style="pickupRequestStyle(request.status)" class="uppercase">
              <span :class="request.newLog && 'font-bold'">
                {{ request.status || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </span>
              <div
                v-if="request.newLog"
                class="absolute new-text font-medium text-text-color-danger top-0 right-[17px]"
              >
                new
              </div>
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="request"
              :idx="index"
              :action-list="actionList"
              @action="userActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(requestsCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
    <RespondRequestModal
      v-if="showRespondModal"
      :modal="showRespondModal"
      :current-request="currentSelectedRequest"
      @toggle="toggleRespondModal"
    />
    <RejectRequestModal
      v-if="confirmation"
      :modal="confirmation"
      heading="Reject Request"
      :current-request="currentSelectedRequest"
      button-text="Reject"
      @toggle="toggleConfirmationModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import { upComingRow } from '@src/utils/generalUtil'
import isEmpty from 'lodash/isEmpty'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import RejectRequestModal from '@src/router/views/pickup/pickup-support/modal/RejectRequestModal.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import RespondRequestModal from '@views/pickup/pickup-support/modal/RespondRequestModal.vue'
import { pickupRequestStyle, redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import icon from '@src/components/icons/icon.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import PickupFilter from '@src/router/views/pickup/pickup-support/PickupRequestFilter.vue'
import PickupRequestData from '@src/router/views/pickup/pickup-support/pickup-request.json'
import fileMixins from '@/src/mixins/file-mixins'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import { dropdownTopBottom, objectDeepCopy, showColumnInDataTable } from '@src/utils/generalUtil.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    PickupFilter,
    TableAction,
    Pagination,
    Loader,
    RejectRequestModal,
    SingleUserDisplay,
    RespondRequestModal,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      isEmpty,
      TENANT_ROLES,
      DATE_TIME_CONSTANT,
      FILTER_KEYS,
      isLoading: false,
      actionList: [{ name: 'TA_R' }, { name: 'TA_REJECT_R' }],
      tableHeaders: PickupRequestData.tableHeaders,
      currentSelectedRequest: {},
      defaultSelectedColumns: PickupRequestData.defaultSelectedColumns,
      filteredRecordLimit: 10,
      confirmation: false,
      showPagination: false,
      requestsList: [],
      $where: {},
      filtersData: {},
      showRespondModal: false,
      requestsCounts: 0,
    }
  },

  computed: {
    showBreadcrumb() {
      return this.activeRole === TENANT_ROLES.SUPER_ADMIN
    },
    ...mapState({
      tabRightBar: (state) => state.layout.tabRightNav,
      currentUser: (state) => state.auth.currentUser,
      pickupAssignee: (state) => state.auth.pickupAssignee,
    }),
  },
  watch: {
    currentCampusScope: {
      immediate: true,
      handler() {
        this.filterRecords()
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.filterRecords()
      },
    },
  },

  mounted() {
    this.setRightbar()
    this.tableHeaders[5].skipForFilterOptions = true
    this.defaultSelectedColumns.splice(0, 1, 'Last Three Activities')
    this.tableHeaders[1].dataTableName = 'Last Three Activities'
    this.tableHeaders[1].backendKeyForCSV = 'Last Three Activities'
    this.tableHeaders[1].readableValueForCSV = 'Last Three Activities'
    this.filterRecords()
  },
  methods: {
    redirectToStudentMode,
    dropdownTopBottom,
    showColumnInDataTable,
    pickupRequestStyle,
    upComingRow,
    userActions(action, currentRequest, idx) {
      this.currentSelectedRequest = currentRequest
      this.currentUserPosition = idx
      switch (action) {
        case 'TA_R':
          this.toggleRespondModal()
          break
        case 'TA_REJECT_R':
          this.toggleConfirmationModal()
          break
      }
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },

    toggleRespondModal(payload) {
      this.showRespondModal = !this.showRespondModal
      if (!this.showRespondModal) this.currentSelectedRequest = null
      if (payload === 'fetch') this.filterRecords()
    },

    toggleConfirmationModal(payload) {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentSelectedRequest = null
      if (payload === 'fetch') this.filterRecords()
    },

    filterRecords(range) {
      this.requestLogs = []
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      payload = {
        ...payload,
        ...{
          $where: {
            ...this.filtersData?.$where,
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'status', 'active'),
          },
        },
      }
      this.getAllPickupRequests(payload)
        .then((response) => {
          this.requestsList = response.records
          this.requestsCounts = response.meta.total_records
          this.requestsList.forEach((request) => {
            fullName(request.student)
            fullName(request.pickup_person)
            if (request.student?.student_info?.main_guardian)
              fullName(request.student?.student_info?.main_guardian)
          })

          this.showPagination = this.requestsCounts > 10
          this.$nextTick(() => {
            this.initializePickupSocket()
          })

          this.setRightbar(this.requestsCounts)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    initializePickupSocket() {
      const authToken = localStorage.getItem('jwt') || null
      if (!this.$pickupSocket) this.$connectPickupSocket('/tenant-api-socket', authToken)

      if (this.$pickupSocket) {
        const pickupChannel = this.$pickupSocket.channel(
          `pickup_request:request_with_logs:${this.currentUser?.id}`,
        )

        pickupChannel.on('pickuprequestcreate', (request) => {
          fullName(request.student)
          fullName(request.pickup_person)

          request.new = true

          if (this.requestsList.length === 10) {
            this.requestsList.pop()
            this.requestsList.unshift(request)
          } else this.requestsList.unshift(request)

          this.requestsCounts++
          this.$store.commit(
            'toast/NEW',
            {
              message: `New Request Arrived of ${request.student.full_name}`,
              type: 'notification',
              image: request.pickup_request_logs[0].inserted_by.image,
              related_user: request.pickup_request_logs[0].inserted_by,
              inserted_at: request.pickup_request_logs[0].inserted_at,
            },
            { root: true },
          )
          this.setRightbar(this.requestsCounts)
        })

        pickupChannel.on('pickuprequestupdate', (log) => {
          let isAvailable = false
          let matchedRequest = null
          let matchedIndex = null
          fullName(log.student)
          fullName(log.pickup_person)
          this.requestsList.forEach((request, index) => {
            if (request.id === log.id) {
              isAvailable = true
              matchedRequest = request
              matchedIndex = index
              if (['processed', 'closed'].includes(log.status)) request.status = log.status
            }
          })

          /**
           * When request is in list and list count is 10 or less moved request to top
           * When request is not available and list count is 10 removed one from end and add new on top
           * In other cased just move request to top
           */

          if (isAvailable && this.requestsList.length <= 10) {
            this.requestsList.splice(matchedIndex, 1)
            matchedRequest['newLog'] = true
            matchedRequest['pickup_request_logs'] = log.pickup_request_logs
            this.requestsList.unshift(matchedRequest)
            if (log.closed_at || log.rejected_at || log.picked_at) {
              setTimeout(() => this.requestsList.splice(0, 1), 1000)
            }
          } else if (!isAvailable && this.requestsList.length === 10) {
            this.requestsList.pop()
            log['newLog'] = true
            log['pickup_request_logs'] = log.pickup_request_logs
            this.requestsList.unshift(log)
            if (log.closed_at || log.rejected_at || log.picked_at) {
              setTimeout(() => this.requestsList.splice(0, 1), 1000)
            }
          } else {
            log['newLog'] = true
            log['pickup_request_logs'] = log.pickup_request_logs
            this.requestsList.unshift(log)
            if (log.closed_at || log.rejected_at || log.picked_at) {
              setTimeout(() => this.requestsList.splice(0, 1), 1000)
            }
          }

          this.$store.commit('toast/NEW', {
            message: ` ${log.pickup_request_logs[0].message}`,
            type: 'notification',
            image: log.pickup_request_logs[0].inserted_by.image,
            related_user: log.pickup_request_logs[0].inserted_by,
            inserted_at: log.pickup_request_logs[0].inserted_at,
          })
        })

        pickupChannel.onError(() => {
          // eslint-disable-next-line no-console
          console.log('Error')
        })
        pickupChannel
          .join()
          .receive('ok', ({ message }) => console.log(message)) // eslint-disable-line no-console
          .receive('error', ({ reason }) => console.log('failed join', reason)) // eslint-disable-line no-console
          .receive('timeout', () => console.log('Networking issue. Still waiting...')) // eslint-disable-line no-console
      }
    },
    setRightbar(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Pickup Requests',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'ACTIVE_REQUESTS',
                value: count,
                tooltipDescription: 'Total Active Request in Student Dispatcher',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('pickup', ['getAllPickupRequests']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

<style lang="scss" scoped>
td.request-logs > div:not(:last-child) {
  border-bottom: 1px solid var(--primary-grey);
}

.new-bagde {
  top: 0;
  right: 17px;
}
.new-text {
  font-size: 9px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
