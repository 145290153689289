<template>
  <ValidationObserver @submit="closeSelectedRequest">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="40"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>{{ heading }}</span>
      </template>
      <template v-slot>
        <UserDetailedView :user="userCandidate" />
        <div class="pt-6">
          <InputFieldWrapper v-if="!isLoading">
            <UiInput
              v-model="customMessage"
              type="textarea"
              name="Reason"
              title="Reason"
              label="Reason"
              placeholder="WRITE"
              rules="required|max:1000"
              class="flex-1 w-1/5"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-1 flex gap-4 flex-row">
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-9 w-23.5 flex justify-center items-center primary-button-outline"
            :disabled="isLoading"
            @click="handleClick('close')"
          >
            Cancel
          </button>
          <button
            :disabled="isLoading"
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-9 w-23.5 flex justify-center items-center primary-button"
          >
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>{{ buttonText }}</span>
            </template>
          </button>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UserDetailedView from '@src/components/userDetailedView.vue'
import { mapActions, mapState } from 'vuex'
import generalConstants from '@src/constants/general-constants.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import Loader from '@components/BaseComponent/Loader.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { pickupRequestStyle } from '@src/utils/settings/tenant-user.util.js'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    Loader,
    UserDetailedView,
    UiInput,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    buttonText: {
      type: String,
      default: 'Closed',
    },
    heading: {
      type: String,
      default: 'Closed Request',
    },
    currentRequest: { type: Object, default: () => {} },
  },
  emits: ['toggle', 'fetch'],
  data() {
    return {
      students: [],
      pickUp: 'pickUp',
      selectedResponse: null,
      isLoading: false,
      isTime: false,
      isCustom: false,
      userCandidate: null,
      time: null,
      customMessage: '',
      responseData: {
        status: '',
        message: '',
      },
    }
  },

  computed: {
    ...mapState('layout', ['currentSelectedCampus']),
  },
  mounted() {
    this.getUserInfo(this.currentRequest.student)
  },
  methods: {
    getUserInfo(data) {
      let queryParam = paginationRangeHandler(data?.range, generalConstants.MODAL_RECORD_LIMIT)
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
          this.userCandidate.requestStatus = this.currentRequest.status
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    pickupRequestStyle,
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle', 'fetch')
          break
      }
    },
    async closeSelectedRequest() {
      this.responseData.message = this.customMessage

      try {
        this.$store.commit('layout/IS_LOADING', true)

        let payload = {
          status: this.responseData.status || '',
          message: this.responseData.message || '',
          pickup_request_id: this.currentRequest?.id || '',
        }
        await this.rejectActivePickupRequest(payload).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
        })
        this.handleClick('close')
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('pickup', ['rejectActivePickupRequest']),
    ...mapActions('users', ['getUserDetails']),
  },
}
</script>
